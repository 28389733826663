.spinnerContainer {
    height: 140px;
    width: 140px;
    padding: 5px;
}
.circle {
    fill: #EBE2F8;
}
.piece {
    fill: #8026F5;
}

.spin {
    animation: slowSpin 1.35s linear infinite;
}

@keyframes slowSpin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
