body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: 'Ginto';
  font-weight: 200;
  src: local('Ginto'), url('./assets/fonts/Ginto/GintoLight.ttf') format('opentype');
}


@font-face {
  font-family: 'Ginto';
  font-weight: 300;
  src: local('Ginto'), url('./assets/fonts/Ginto/GintoThin.ttf') format('opentype');
}

@font-face {
  font-family: 'Ginto';
  font-weight: 400;
  src: local('Ginto'), url('./assets/fonts/Ginto/GintoRegular.ttf') format('opentype');
}

@font-face {
  font-family: 'Ginto';
  font-weight: 500;
  src: local('Ginto'), url('./assets/fonts/Ginto/GintoMedium.ttf') format('opentype');
}

@font-face {
  font-family: 'Ginto';
  font-weight: 700;
  src: local('Ginto'), url('./assets/fonts/Ginto/GintoBold.ttf') format('opentype');
}


@font-face {
  font-family: 'Ginto Nord';
  font-weight: 200;
  src: local('Ginto Nord'), url('./assets/fonts/Ginto_Nord/GintoNordLight.ttf') format('opentype');
}


@font-face {
  font-family: 'Ginto Nord';
  font-weight: 300;
  src: local('Ginto Nord'), url('./assets/fonts/Ginto_Nord/GintoNordThin.ttf') format('opentype');
}

@font-face {
  font-family: 'Ginto Nord';
  font-weight: 400;
  src: local('Ginto Nord'), url('./assets/fonts/Ginto_Nord/GintoNordRegular.ttf') format('opentype');
}

@font-face {
  font-family: 'Ginto Nord';
  font-weight: 500;
  src: local('Ginto Nord'), url('./assets/fonts/Ginto_Nord/GintoNordMedium.ttf') format('opentype');
}

@font-face {
  font-family: 'Ginto Nord';
  font-weight: 700;
  src: local('Ginto Nord'), url('./assets/fonts/Ginto_Nord/GintoNordBold.ttf') format('opentype');
}
@font-face {
  font-family: 'HW Cigars';
  src: local('HW Cigars'), url('./assets/fonts/Hw_Cigars/hw-cigars-light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HW Cigars';
  src: local('HW Cigars'), url('./assets/fonts/Hw_Cigars/hw-cigars-regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'HW Cigars';
  src: local('HW Cigars'), url('./assets/fonts/Hw_Cigars/hw-cigars-medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HW Cigars';
  src: local('HW Cigars'), url('./assets/fonts/Hw_Cigars/hw-cigars-semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'HW Cigars';
  src: local('HW Cigars'), url('./assets/fonts/Hw_Cigars/hw-cigars-bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

.teku-primary {
  color: #622C4A;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: -0.02em;
  text-align: left;

}

.slick-slide {
  padding: 0 11px;
  box-sizing: border-box;
}